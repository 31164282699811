import { AsyncPipe, NgClass } from '@angular/common';
import { Component, effect, ElementRef, HostListener, inject, OnDestroy, viewChild } from '@angular/core';
import { ButtonComponent } from '@progress/kendo-angular-buttons';
import { PopupComponent } from '@progress/kendo-angular-popup';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';
import { NotificationService } from '@core/services/notification.service';
import { GridComponent, GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { DatePipe } from '@progress/kendo-angular-intl';
import { KendoGridComponent } from '@shared/components/kendo-grid/kendo-grid.component';
import { State } from '@progress/kendo-data-query';
import { HttpClient } from '@angular/common/http';
import { CapitalizePipe } from '@core/pipes/capitalize.pipe';
import { HydraCollectionResponse } from '@core/models/data/HydraCollectionResponse';
import { environment } from '@environments/environment';
import { Notification } from '@core/models/component/notification';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { KendoMessageService } from '@core/services/kendo-message.service';
import { MercureService } from '@core/services/mercure.service';
import { ApplicationUserService } from '@core/services/application-user.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { isEmptyObject } from '@core/utils/object-utils/object-utils';

@Component({
  selector: 'rc-user-notifications',
  standalone: true,
  templateUrl: './user-notifications.component.html',
  styleUrl: './user-notifications.component.scss',
  imports: [AsyncPipe, ButtonComponent, PopupComponent, GridModule, DatePipe, NgClass, TranslateModule, CapitalizePipe],
  providers: [CapitalizePipe, TranslatePipe, KendoMessageService],
})
export class UserNotificationsComponent extends KendoGridComponent implements OnDestroy {
  notificationService = inject(NotificationService);
  showNotificationsPopUp = false;
  #httpClient = inject(HttpClient);
  #mercureService = inject(MercureService<Notification>);
  #applicationUserService = inject(ApplicationUserService);
  gridData$: Observable<GridDataResult>;
  updateData$ = new BehaviorSubject<void>(undefined);
  loading: boolean;
  _eventSource: EventSource | null;
  totalNotifications: number;
  accumulatedData: Notification[] = [];
  kendoMessageService = inject(KendoMessageService);
  kendoPopup = viewChild('notificationsPopup', { read: ElementRef });
  anchor = viewChild('notificationAnchor', { read: ElementRef });
  user = toSignal(this.#applicationUserService.user$);
  grid = viewChild.required(GridComponent);
  totalAmountToRead$: Observable<number> = this.notificationService.getTotalAmountToRead();
  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'escape') {
      this.toggle(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (event.target && !this.contains(event.target)) {
      this.toggle(false);
    }
  }

  constructor() {
    super();
    this.gridData$ = this.#getNotifications();
    effect(
      () => {
        if (this.kendoPopup() && this.grid()) {
          this.gridState = {
            skip: 0,
            take: 3,
          };

          this.gridState = this.convertQueryParamsToGridState(this.grid()!, this.gridState);
          this.gridData$ = this.#getNotifications();
        }
      },
      { allowSignalWrites: true },
    );
  }

  onDataStateChange(state: State): void {
    this.gridState = state;
    this.accumulatedData = [];
    this.gridState.skip = 0;
    this.updateData$.next();
  }

  loadMore(): void {
    if (this.gridState.take && this.gridState.skip! + this.gridState.take < this.totalNotifications) {
      this.loading = true;
      this.gridState.skip! += this.gridState.take;
      this.updateData$.next();
    }
  }

  toggleNotifications() {
    this.showNotificationsPopUp = !this.showNotificationsPopUp;
    this.kendoMessageService.setMessageForKey({
      key: 'kendo.grid.noRecords',
      message: { text: 'NO_NOTIFICATIONS', capitalize: true },
    });
    if (!this.showNotificationsPopUp) {
      this.#resetGrid();
      this.kendoMessageService.setMessageForKey({
        key: 'kendo.grid.noRecords',
        message: { text: 'NO_RECORDS', capitalize: true },
      });
    }
  }

  #getNotifications(): Observable<GridDataResult> {
    return this.updateData$.asObservable().pipe(
      tap(() => this.isLoadingGridData$.next(true)),
      switchMap(() =>
        this.#httpClient
          .get<HydraCollectionResponse<Notification>>(`${environment.apiUrl}/notifications`, {
            params: this.convertGridStateToQueryParams(),
          })
          .pipe(
            map((response) => {
              this.totalNotifications = response['hydra:totalItems'];
              return {
                total: response['hydra:totalItems'],
                data: response['hydra:member'],
              };
            }),
            switchMap((data) => {
              this._eventSource = this.user()
                ? this.#mercureService.subscribeToSpecificTopic(
                    data.data,
                    this._eventSource,
                    `/users/${this.user()!.id}/notifications`,
                  )
                : null;

              return this.#mercureService.getUpdates(this._eventSource).pipe(
                map((update) => {
                  this.totalAmountToRead$ = this.notificationService.getTotalAmountToRead();
                  // Update existing notifications
                  data.data = data.data.map((notification) => {
                    if (notification['@id'] === update['@id']) {
                      return update;
                    }
                    return notification;
                  });
                  if (!data.data.find((n) => n['@id'] === update['@id'])) {
                    if (!isEmptyObject(update)) {
                      data.data.unshift(update);
                    }
                  }

                  return { ...data };
                }),
              );
            }),
          ),
      ),
      tap(() => this.isLoadingGridData$.next(false)),
    );
  }

  #resetGrid() {
    this.gridState = {
      skip: 0,
      take: 3,
    };
    this.accumulatedData = [];
    this.totalNotifications = 0;
    this.loading = false;
    this.isLoadingGridData$.next(false);
  }

  public toggle(show: boolean): void {
    this.showNotificationsPopUp = show;
  }

  private contains(target: EventTarget): boolean {
    return (
      this.anchor()?.nativeElement.contains(target) ||
      (this.kendoPopup() ? this.kendoPopup()!.nativeElement.contains(target) : false)
    );
  }

  ngOnDestroy(): void {
    this._eventSource?.close();
    this._eventSource = null;
  }
}
