import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CapitalizePipe } from '@core/pipes/capitalize.pipe';

@Component({
  selector: 'rc-primary-nav',
  standalone: true,
  templateUrl: './primary-nav.component.html',
  styleUrl: './primary-nav.component.scss',
  imports: [RouterLink, RouterLinkActive, TooltipModule, TranslateModule, CapitalizePipe],
})
export class PrimaryNavComponent {}
