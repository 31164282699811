import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiHttpService } from '@core/services/api-http.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private ApiHttpService = inject(ApiHttpService);

  markRead(notification: any) {
    this.ApiHttpService.put(`${notification['@id']}/read`, notification).subscribe();
  }

  markAllAsRead() {
    this.ApiHttpService.put(`/notifications/read`, {}).subscribe();
  }

  getTotalAmountToRead(): Observable<number> {
    return this.ApiHttpService.get(`/notifications/amount_to_read`).pipe(
      map((response: any) => (response.body ? (response.body as AmountToReadResponse).amountToRead : 0)),
    );
  }
}

interface AmountToReadResponse {
  amountToRead: number;
}
