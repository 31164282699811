import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { distinctUntilChanged, filter } from 'rxjs';
import { CapitalizePipe } from '@core/pipes/capitalize.pipe';

const customTranslationKeys: Record<string, string | { text: string; capitalize: boolean }> = {
  'kendo.grid.pagerOf': 'GRID_PAGINATOR_OF',
  'kendo.grid.pagerItems': 'RESULTS',
  'kendo.grid.noRecords': { text: 'NO_RECORDS', capitalize: true },
  'kendo.upload.uploadSelectedFiles': 'Upload',
  'kendo.upload.clearSelectedFiles': 'Clear',
  'kendo.upload.invalidFileExtension': 'FileTypeNotAllowed',
  'kendo.upload.invalidMaxFileSize': 'File_SizeLimit_Error',
  'kendo.upload.dropFilesHere': { text: 'DROP_FILES', capitalize: false },
  'kendo.timepicker.cancel': { text: 'CANCEL', capitalize: true },
  'kendo.timepicker.accept': { text: 'SELECT', capitalize: true },
  'kendo.timepicker.now': { text: 'NOW', capitalize: true },
  'kendo.datetimepicker.now': { text: 'NOW', capitalize: true },
  'kendo.datetimepicker.today': { text: 'TODAY', capitalize: true },
  'kendo.datepicker.today': { text: 'TODAY', capitalize: true },
  'kendo.datetimepicker.accept': { text: 'SELECT', capitalize: true },
  'kendo.datepicker.accept': { text: 'SELECT', capitalize: true },
  'kendo.datetimepicker.cancel': { text: 'CANCEL', capitalize: true },
  'kendo.datepicker.cancel': { text: 'CANCEL', capitalize: true },
  'kendo.datepicker.timeTab': { text: 'TIME', capitalize: true },
  'kendo.datetimepicker.timeTab': { text: 'TIME', capitalize: true },
  'kendo.datepicker.dateTab': { text: 'DATE', capitalize: true },
  'kendo.datetimepicker.dateTab': { text: 'DATE', capitalize: true },
  'kendo.upload.select': { text: 'SELECT_FILE', capitalize: true },
};

@Injectable()
export class KendoMessageService extends MessageService {
  localeId: string;
  #translationService = inject(TranslateService);
  #capitalizePipe = new CapitalizePipe();

  constructor() {
    super();
    this.listenForLanguageChange();
  }

  override get(_key: string): string | undefined {
    const key = customTranslationKeys[_key];
    if (key) {
      if (typeof key === 'string') {
        return this.#translationService.instant(key);
      }
      if (key.capitalize) {
        return this.#capitalizePipe.transform(this.#translationService.instant(key.text));
      } else {
        return this.#translationService.instant(key.text);
      }
    }
    return undefined;
  }

  private listenForLanguageChange(): void {
    this.#translationService.onLangChange
      .asObservable()
      .pipe(filter(Boolean), distinctUntilChanged())
      .subscribe((lang) => {
        this.localeId = lang.lang;
        this.notify();
      });
  }

  setMessageForKey(object: { key: string; message: string | { text: string; capitalize: boolean } }) {
    customTranslationKeys[object.key] = object.message;
  }
}
