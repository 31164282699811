import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationUserService } from '@core/services/application-user.service';
import { inject } from '@angular/core';

export function languageHttpInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const user = inject(ApplicationUserService).user();
  if (user) {
    const newReq = req.clone({
      headers: req.headers.append('Accept-Language', user.settings.language),
    });
    return next(newReq);
  }
  return next(req);
}
