<div class="k-d-flex k-justify-content-center k-align-items-center">
  <rc-user-notifications></rc-user-notifications>
  <span href="#" class="k-grid-cols-4 user-menu__current-user">
    {{ (authService.user$ | async)?.name }}
    <ul class="user-menu__dropdown">
      @for (menuItem of menuItems; track menuItem) {
        <li
          tabindex="0"
          (keydown.enter)="menuItem.clickAction()"
          (click)="menuItem.clickAction()"
          class="user-menu__item">
          @if (menuItem.icon) {
            <i class="menu-icon" [class]="menuItem.icon"></i>
          }
          <span class="menu-text">
            {{ menuItem.text | translate | capitalize }}
          </span>
        </li>
      }
    </ul>
  </span>
</div>
