<ul class="primary-nav__menu">
  <li class="primary-nav__menu-item">
    <a
      routerLink="/dashboard"
      class="primary-nav__link"
      attr.aria-label="{{ 'DASHBOARD' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      kendoTooltip
      [tooltipClass]="'primary-nav-item'"
      title="{{ 'DASHBOARD' | translate | capitalize }}"
      position="right">
      <i class="rc-icon-home"></i>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/clients"
      class="primary-nav__link primary-nav__link--active"
      attr.aria-label="{{ 'CLIENTS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      kendoTooltip
      [tooltipClass]="'primary-nav-item'"
      title="{{ 'CLIENTS' | translate | capitalize }}"
      position="right">
      <i class="rc-icon-store"></i>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/orders"
      class="primary-nav__link"
      attr.aria-label="{{ 'ORDERS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      kendoTooltip
      [tooltipClass]="'primary-nav-item'"
      title="{{ 'ORDERS' | translate | capitalize }}"
      position="right">
      <i class="rc-icon-clipboard-list"></i>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/suppliers"
      class="primary-nav__link"
      attr.aria-label="{{ 'SUPPLIERS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      kendoTooltip
      [tooltipClass]="'primary-nav-item'"
      title="{{ 'SUPPLIERS' | translate | capitalize }}"
      position="right">
      <i class="rc-icon-skyscraper"></i>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/supplier_articles"
      class="primary-nav__link"
      attr.aria-label="{{ 'ARTICLES' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      kendoTooltip
      [tooltipClass]="'primary-nav-item'"
      title="{{ 'ARTICLES' | translate | capitalize }}"
      position="right">
      <i class="rc-icon-microwave"></i>
    </a>
  </li>
  <li class="primary-nav__menu-item">
    <a
      routerLink="/settings"
      class="primary-nav__link"
      attr.aria-label="{{ 'SETTINGS' | translate | capitalize }}"
      routerLinkActive="primary-nav__link--active"
      kendoTooltip
      [tooltipClass]="'primary-nav-item'"
      title="{{ 'SETTINGS' | translate | capitalize }}"
      position="right">
      <i class="rc-icon-settings"></i>
    </a>
  </li>
</ul>
